import { CustomerData } from '@app/@types/customer.types';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import ChargeEventsContextComponent from '@app/contexts/ChargeEventsContextComponent';
import Accounting from '@app/pages/Accounting/Accounting';
import Quickbooks from '@app/pages/Accounting/Quickbooks';
import ComparePlans from '@app/pages/AtoBPremium/ComparePlans';
import PremiumFeatures from '@app/pages/AtoBPremium/PremiumFeatures';
import PremiumOptinPage from '@app/pages/AtoBUnlimited/PremiumOptinPage';
import UnlimitedFeatures from '@app/pages/AtoBUnlimited/UnlimitedFeatures';
import { BillingStatements } from '@app/pages/BillingStatements/BillingStatements';
import CardDetail from '@app/pages/CardDetails/CardDetails';
import CardsPage from '@app/pages/Cards/CardsPage';
import { DevicesPage } from '@app/pages/Devices/DevicesPage';
import DriversPage from '@app/pages/Drivers/DriversPage';
import Fleetio from '@app/pages/Fleetio/Fleetio';
import FuelMapPage from '@app/pages/FuelMap/FuelMap';
import Integrations from '@app/pages/Integrations/Integrations';
import TelematicsConnectedSuccessPage from '@app/pages/Integrations/Telematics/TelematicsConnectedSuccessPage';
import Logout from '@app/pages/Logout/Logout';
import NotFound from '@app/pages/NotFound/NotFound';
import OnboardingWizard from '@app/pages/Onboarding/OnboardingWizard';
import WalletFunding from '@app/pages/Partnerships/WalletFunding/Funding';
import PaymentHistory from '@app/pages/PaymentHistory/PaymentHistoryPage';
import PaymentMethodsPage from '@app/pages/PaymentMethods/PaymentMethodsPage';
import Payroll from '@app/pages/Payroll/Payroll';
import PayrollDriverDetails from '@app/pages/Payroll/PayrollDriverDetails';
import PayrollHistory from '@app/pages/PayrollHistory/PayrollHistory';
import Perks from '@app/pages/Perks/Perks';
import Referrals from '@app/pages/Referrals';
import { ReportingPage } from '@app/pages/Reporting/ReportingPage';
import RequestCredit from '@app/pages/RequestCredit/RequestCredit';
import {
  Settings,
  SettingsPageDriverRequestCallback,
  SettingsPagePatchCallback,
} from '@app/pages/Settings';
import { SwitchCustomer } from '@app/pages/SwitchCustomer/SwitchCustomer';
import { SwitchPartners } from '@app/pages/SwitchPartners/SwitchPartners';
import { SwitchPartnersSuccess } from '@app/pages/SwitchPartners/SwitchPartnersSuccess';
import CreateTagsPage from '@app/pages/Tags/CreateTagsPage';
import EditDefaultSettingsPage from '@app/pages/Tags/EditDefaultSettingsPage';
import EditTagsPage from '@app/pages/Tags/EditTagsPage';
import TagsPage from '@app/pages/Tags/TagsPage';
import SuspiciousActivitiesPage from '@app/pages/Telematics/SuspiciousActivities/SuspiciousActivitiesPage';
import TelematicsConnectionFormPage from '@app/pages/Telematics/TelematicsConnectionForm/TelematicsConnectionFormPage';
import HandleTelematicsAuthCallback from '@app/pages/Telematics/TelematicsUtils/HandleTelematicsAuthCallback';
import TransactionDetail from '@app/pages/TransactionDetail/TransactionDetail';
import TransactionDetailRedirect from '@app/pages/TransactionDetail/TransactionDetailRedirect';
import Transactions from '@app/pages/Transactions/Transactions';
import UberFreightConversionLandingPage from '@app/pages/UberFreightConversion/UberFreightConversionLandingPage';
import UberFreightConversionSorryPage from '@app/pages/UberFreightConversion/UberFreightConversionSorryPage';
import Users from '@app/pages/Users/Users';
import Vehicles from '@app/pages/Vehicles/Vehicles';
import WalletOverview from '@app/pages/Wallet/Overview';
import WalletRecipientsOverview from '@app/pages/Wallet/RecipientsOverview';
import WalletLinkExpired from '@app/pages/Wallet/WalletLinkExpired';
import * as Sentry from '@sentry/react';
import React, { ReactElement } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import PrepaidAccountOverview from '../components/PrepaidAccountOverview/PrepaidAccountOverview';
import { CustomerProduct, findSpendTier, workspaceOnly } from '../constants/customerProducts';
import { spendTierToInt } from '../constants/spendTier';
import useChannelPartner from '../hooks/useChannelPartner';
import useFeatureFlags from '../hooks/useFeatureFlags';
import CreditAccountOverview from '../pages/AccountOverview/CreditAccountOverview';
import { getLoadPayRoutes } from './LoadPay/getLoadPayRoutes';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function AppRoutes({
  customer,
  isLoading,
}: {
  customer: CustomerData;
  isLoading: boolean;
}): ReactElement {
  const { hasChannelPartner } = useChannelPartner();
  const [isPrepaidPending, isBusinessCreditReportingEnabled] = useFeatureFlags(
    'prepaid_pending',
    'business_credit_reporting',
  );
  const products = customer.products;

  return (
    <SentryRoutes>
      {computeRoutes(products, [
        ...getLoadPayRoutes(customer, products),
        {
          route: getIndexRoute(customer, isLoading),
          requires: [],
        },
        {
          route: (
            <Route path="/cards" element={<Outlet />}>
              <Route
                index
                element={
                  <CardsPage
                    showTags={
                      products.includes('resource_tags') && products.includes('cards_spend_limit')
                    }
                    telematicsEnabled={products.includes('telematics')}
                    cardRequestsEnabled={products.includes('card_requests')}
                  />
                }
              />
              <Route
                path=":id"
                element={
                  <CardDetail
                    allowSetSpendLimit={products.includes('cards_spend_limit')}
                    smsUnlockEnabled={products.includes('sms_unlock')}
                    telematicsEnabled={products.includes('telematics')}
                    resourceTagsEnabled={products.includes('resource_tags')}
                  />
                }
              />
            </Route>
          ),
          requires: ['cards'],
        },
        {
          // Transactions Page
          // TODO(ryan) - https://linear.app/atob/issue/ENGP-2697/clean-up-the-tech-debt
          route: (
            <Route
              path="transactions"
              element={
                <ChargeEventsContextComponent>
                  <Outlet />
                </ChargeEventsContextComponent>
              }
            >
              <Route index element={<Transactions />} />
              <Route path=":transactionId" element={<TransactionDetail />} />
            </Route>
          ),
          requires: ['cards'],
        },
        {
          route: (
            <Route
              path="/transaction_details/:transactionId"
              element={<TransactionDetailRedirect />}
            />
          ),
          requires: ['cards'],
        },
        {
          route: <Route path="/billing/statements" element={<BillingStatements />} />,
          requires: ['billing'],
        },
        {
          route: (
            <Route
              path="/billing/payment-history"
              element={
                <PaymentHistory
                  withContentWrapper
                  showMonthlySubscription={products.includes('show_subscription_payments')}
                />
              }
            />
          ),
          requires: ['billing'],
        },
        {
          route: <Route path="/billing/payment-methods" element={<PaymentMethodsPage />} />,
          requires: ['payment_methods'],
        },
        !hasChannelPartner && {
          route: (
            <Route
              path="/referrals"
              element={<Referrals isPrepaid={products.includes('prepaid')} />}
            />
          ),
          requires: ['refer_and_earn'],
        },
        {
          route: <Route path="/discounts-catalog" element={<Perks />} />,
          requires: ['perks'],
        },
        {
          route: <Route path="/perks" element={<FuelMapPage />} />,
          requires: ['fuel_finder'],
        },
        {
          route: <Route path="/fuel-map" element={<FuelMapPage />} />,
          requires: ['fuel_finder'],
        },
        {
          route: (
            <Route
              path="/drivers"
              element={<DriversPage resourceTagsEnabled={products.includes('resource_tags')} />}
            />
          ),
          requires: ['drivers'],
        },
        {
          route: (
            <Route
              path="/tags"
              element={<TagsPage allowTelematics={products.includes('telematics')} />}
            />
          ),
          requires: ['resource_tags'],
        },
        {
          route: (
            <Route
              path="/tags/:id"
              element={
                <EditTagsPage
                  allowSetSpendLimit={products.includes('cards_spend_limit')}
                  allowRestrictions={products.includes('spend_restrictions')}
                  spendTier={spendTierToInt(findSpendTier(products))}
                  allowTelematics={products.includes('telematics')}
                />
              }
            />
          ),
          requires: ['resource_tags'],
        },
        {
          route: (
            <Route
              path="/policies/default"
              element={
                <EditDefaultSettingsPage
                  allowSetSpendLimit={products.includes('cards_spend_limit')}
                  allowRestrictions={products.includes('spend_restrictions')}
                  allowTelematics={products.includes('telematics')}
                  spendTier={spendTierToInt(findSpendTier(products))}
                />
              }
            />
          ),
          requires: [],
        },
        {
          route: (
            <Route
              path="/tags/new"
              element={
                <CreateTagsPage
                  allowSetSpendLimit={products.includes('cards_spend_limit')}
                  allowRestrictions={products.includes('spend_restrictions')}
                  allowTelematics={products.includes('telematics')}
                  spendTier={spendTierToInt(findSpendTier(products))}
                />
              }
            />
          ),
          requires: ['resource_tags'],
        },
        {
          route: (
            <Route
              path="/payroll/overview"
              element={<Payroll isPrepaid={products.includes('prepaid') || isPrepaidPending} />}
            />
          ),
          requires: ['payroll'],
        },
        {
          route: <Route path="/payroll/history" element={<PayrollHistory />} />,
          requires: ['payroll_history'],
        },
        {
          route: <Route path="/payroll/history/taxes" element={<PayrollHistory />} />,
          requires: ['payroll_history'],
        },
        {
          route: <Route path="/payroll/driver/:id" element={<PayrollDriverDetails />} />,
          requires: ['payroll'],
        },
        {
          route: (
            <Route
              path="telematics/suspicious-activities"
              element={
                <ChargeEventsContextComponent>
                  <Outlet />
                </ChargeEventsContextComponent>
              }
            >
              <Route
                index
                element={
                  <SuspiciousActivitiesPage
                    resourceTagsEnabled={products.includes('resource_tags')}
                  />
                }
              />
              <Route path=":transactionId" element={<TransactionDetail />} />
            </Route>
          ),
          requires: ['suspicious_activities'],
        },
        {
          route: (
            <Route
              path="/telematics/:providerId/connect"
              element={<TelematicsConnectionFormPage />}
            />
          ),
          requires: [],
        },
        {
          route: (
            <Route
              path="/telematics/providers/:providerid/callback"
              element={<HandleTelematicsAuthCallback />}
            />
          ),
          requires: [],
        },
        {
          route: <Route path="/fleetio/connect" element={<Fleetio />} />,
          requires: ['fleetio'],
        },
        {
          route: <Route path="/accounting/quickbooks" element={<Quickbooks />} />,
          requires: ['quickbooks'],
        },
        {
          route: (
            <Route
              path="/accounting"
              element={<Accounting resourceTagsEnabled={products.includes('resource_tags')} />}
            />
          ),
          requires: ['accounting'],
        },
        { route: <Route path="/vehicles" element={<Vehicles />} />, requires: ['vehicles'] },
        {
          route: (
            <Route
              path="/wallet/overview"
              element={
                <WalletOverview
                  customer={customer}
                  customerCompanyName={customer.company_name}
                  customerCompanyAddress={customer.company_address}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  customerTreasuryDetails={customer.treasury}
                  isTrusted={products.includes('treasury_trusted')}
                />
              }
            />
          ),
          requires: ['treasury'],
        },
        {
          route: <Route path="/wallet/funding" element={<WalletFunding />} />,
          requires: ['funding_requests'],
        },
        {
          route: <Route path="/premium_optin" element={<PremiumOptinPage />} />,
          requires: ['treasury'],
        },
        {
          route: <Route path="/wallet/recipients" element={<WalletRecipientsOverview />} />,
          requires: ['treasury_trusted'],
        },
        {
          route: <Route path="/wallet/link-expired" element={<WalletLinkExpired />} />,
          requires: ['treasury'],
        },
        {
          route: <Route path="/devices" element={<DevicesPage />} />,
          requires: ['devices'],
        },
        {
          route: (
            <Route
              path="/settings"
              element={<Settings products={products} customer={customer} />}
            />
          ),
          requires: [],
        },
        {
          route: (
            <Route
              path="/settings/notifications"
              element={
                <Settings products={products} customer={customer} initialTab={'notifications'} />
              }
            />
          ),
          requires: [],
        },
        {
          route: (
            <Route
              path="/settings/plans"
              element={<Settings products={products} customer={customer} initialTab={'plans'} />}
            />
          ),
          requires: ['settings'],
        },
        {
          route: (
            <Route
              path="/settings/account-limits"
              element={
                <Settings products={products} customer={customer} initialTab={'account-limits'} />
              }
            />
          ),
          requires: ['settings'],
        },
        {
          route: (
            <Route
              path="/settings/account_limits"
              element={<Navigate replace to="/settings/account-limits" />}
            />
          ),
          requires: ['settings'],
        },
        isBusinessCreditReportingEnabled && {
          route: (
            <Route
              path="/settings/business-credit-reporting"
              element={
                <Settings
                  products={products}
                  customer={customer}
                  initialTab={'business-credit-reporting'}
                />
              }
            />
          ),
          requires: ['settings'],
        },
        {
          route: (
            <Route
              path="/settings/plans/:planId"
              element={<Settings products={products} customer={customer} initialTab={'plans'} />}
            />
          ),
          requires: ['settings'],
        },
        {
          route: (
            <Route
              path="/settings/plans/:planId/summary"
              element={<Settings products={products} customer={customer} initialTab={'plans'} />}
            />
          ),
          requires: ['settings'],
        },
        {
          route: (
            <Route
              path="/settings/plans/:planId/confirmed"
              element={<Settings products={products} customer={customer} initialTab={'plans'} />}
            />
          ),
          requires: ['settings'],
        },
        {
          route: (
            <Route
              path="/users"
              element={
                <Users
                  workspaceProducts={workspaceOnly(products)}
                  rolesEnabled={products.includes('workspace_roles')}
                  addUsersEnabled={products.includes('workspace_add_update_users')}
                  allowManageNotifications={products.includes('notification_settings')}
                />
              }
            />
          ),
          requires: ['workspace'],
        },
        {
          route: (
            <Route
              path="/request-credit"
              element={
                <RequestCredit customer={customer} isPrepaid={products.includes('prepaid')} />
              }
            />
          ),
          requires: [],
        },
        {
          route: <Route path="/switch-partners/:channelPartnerName" element={<SwitchPartners />} />,
          requires: [],
        },
        {
          route: (
            <Route
              path="/switch-partners/:channelPartnerName/:offerUuid"
              element={<SwitchPartners />}
            />
          ),
          requires: [],
        },
        {
          route: <Route path="/switch-partners-success" element={<SwitchPartnersSuccess />} />,
          requires: [],
        },
        {
          route: (
            <Route
              path="/uberfreightconversion/healthy"
              element={<UberFreightConversionLandingPage />}
            />
          ),
          requires: [],
        },
        {
          route: (
            <Route
              path="/uberfreightconversion/interested"
              element={<UberFreightConversionLandingPage />}
            />
          ),
          requires: [],
        },
        {
          route: (
            <Route
              path="/uberfreightconversion/sorry"
              element={<UberFreightConversionSorryPage />}
            />
          ),
          requires: [],
        },
        {
          route: <Route path="/integrations" element={<Integrations />} />,
          requires: ['integrations'],
        },
        {
          route: (
            <Route path="/integrations/connected" element={<TelematicsConnectedSuccessPage />} />
          ),
          requires: ['integrations'],
        },
        {
          route: <Route path="/premium" element={<PremiumFeatures />} />,
          requires: ['settings'],
        },
        {
          route: <Route path="/premium/compare" element={<ComparePlans />} />,
          requires: ['settings'],
        },
        {
          route: <Route path="/change-request" element={<SettingsPagePatchCallback />} />,
          requires: [],
        },
        {
          route: (
            <Route
              path="/driver-verification-request"
              element={<SettingsPageDriverRequestCallback />}
            />
          ),
          requires: [],
        },
        {
          route: <Route path="/reporting" element={<ReportingPage />} />,
          requires: ['reporting'],
        },
        {
          route: (
            <Route
              path="/unlimited"
              element={<UnlimitedFeatures isPrepaid={products.includes('prepaid')} />}
            />
          ),
          requires: ['settings'],
        },
        { route: <Route path="/logout" element={<Logout />} />, requires: ['logout'] },
        {
          route: <Route path="/switch-customer/:id" element={<SwitchCustomer />} />,
          requires: [],
        },
        { route: <Route path="*" element={<NotFound />} />, requires: [] },
      ])}
    </SentryRoutes>
  );
}

const getIndexRoute = (customer: CustomerData, isLoading: boolean): ReactElement => {
  if (isLoading) {
    return <Route path="/" element={<SpinnerBoundary />} />;
  }
  if (customer.interstitials != null) {
    return <Route path="/" element={<OnboardingWizard />} />;
  }
  if (!customer.products.includes('dashboard')) {
    return <Route path="/" element={<Transactions />} />;
  }
  if (customer.products.includes('prepaid')) {
    return <Route path="/" element={<PrepaidAccountOverview />} />;
  }

  if (!customer.products.includes('fuel_card') && customer.products.includes('payroll')) {
    return (
      <Route path="/" element={<Payroll isPrepaid={customer.products.includes('prepaid')} />} />
    );
  } else {
    return <Route path="/" element={<CreditAccountOverview />} />;
  }
};

export type RouteWithRequires = {
  route: ReactElement;
  requires: CustomerProduct[];
};

const isRouteWithRequires = (
  item: RouteWithRequires | false,
): item is { route: ReactElement; requires: CustomerProduct[] } => {
  return item !== false;
};

const computeRoutes = (
  products: CustomerProduct[],
  routeWithRequires: (false | RouteWithRequires)[],
): ReactElement[] => {
  return routeWithRequires
    .filter(isRouteWithRequires)
    .filter((item) => !!item && item.requires.every((r) => products.includes(r)))
    .map((item, i) => <React.Fragment key={i}>{item.route}</React.Fragment>);
};

export default AppRoutes;
